import React, { useEffect, useState } from 'react';
import { usePlayerUpdatesContext } from '../PlayerUpdatesContext';
import player from '../../Player';
import games2ClickClient from '../../Games2ClickClient';
import { PendingGameItem } from './PendingGameItem';
import { Badge, Collapse, ListItem, Menu, MenuHandler, MenuList, Typography } from '@material-tailwind/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export function PendingGamesMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const {playerUpdate} = usePlayerUpdatesContext();
  const [pendingGames, setPendingGames] = useState([]);

  useEffect(() => fetchPendingGames(), []);
  useEffect(() => {
    if(['newgame','updategame','removegame'].find(item => item === playerUpdate.type)) {
      console.log("got new game, refreshing pending games");
      fetchPendingGames();
    }
  }, [playerUpdate]);


  function fetchPendingGames() {
    player.getPlayerId()
      .then(playerId => games2ClickClient.getPendingGames(playerId))
      .then(games => {
        setPendingGames(games);
      });
  }

  const renderItems = pendingGames.length > 0 &&
    pendingGames.map((pendingGame) => <PendingGameItem pendingGame={pendingGame} />)

  return (
    <React.Fragment>
      <Menu
        open={pendingGames && pendingGames.length > 0 && isMenuOpen}
        handler={setIsMenuOpen}
        offset={{mainAxis: 40}}
        placement="bottom"
        allowHover={false}
      >
        <MenuHandler>
          <Typography as="div" variant="lead" className={`font-medium border rounded-lg ${pendingGames && pendingGames.length > 0 ? '' : 'border-secondary-900'} *:w-full`}>
            <Badge content={pendingGames && pendingGames.length} placement="top-end" className={`z-50 absolute bg-accent right-4 lg:right-0 w-6 h-6 text-black ${pendingGames && pendingGames.length > 0 ? '' : 'hidden'}`}>
              <ListItem
                className={`flex items-center gap-2 py-2 pr-4 font-medium focus:bg-white active:bg-accent ${pendingGames && pendingGames.length > 0 ? 'hover:bg-white hover:bg-opacity-100' : 'bg-transparent active:bg-transparent hover:bg-transparent text-secondary-900 hover:text-secondary-900 hover:cursor-default'}`}
                selected={isMenuOpen || isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen((cur) => !cur)}
              >
                Pending Games
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`hidden h-3 w-3 transition-transform lg:block ${pendingGames && pendingGames.length > 0 ? '' : 'lg:hidden'} ${
                    isMenuOpen ? "rotate-180" : ""
                  }`}
                />
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`block ${pendingGames && pendingGames.length > 0 ? '' : 'hidden'} h-3 w-3 transition-transform lg:hidden ${
                    isMobileMenuOpen ? "rotate-180" : ""
                  }`}
                />
              </ListItem>
            </Badge>
          </Typography>
        </MenuHandler>
        <MenuList onClick={() => setIsMenuOpen(true)} className="hidden max-w-screen-sm rounded-xl border-2 border-secondary-950 bg-secondary-950/30 lg:block max-h-96 pending-games-list backdrop-blur-sm shadow-black">
          <ul className="grid grid-cols-1 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden max-h-96 overflow-y-scroll">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
