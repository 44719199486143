import {NavLink} from "react-router-dom";
import {HomeIcon, PlayCircleIcon, UserIcon} from "@heroicons/react/16/solid";
import FooterStyle from "./footer.module.css";
import React from "react";
import {Breakpoint, useIsBelowBreakpoint} from "./Breakpoints";


export function Footer() {
    const isBelowSmallBreakpoint = useIsBelowBreakpoint(Breakpoint.SMALL);

    function FooterDesktop() {
        return (
            <footer className={FooterStyle.desktop} data-testid="footer">
                <span id="copyright">&copy; Games2Click 2024</span>
                <span id="imprint"><a className="text-accent" rel="noreferrer" target="_blank" href="https://www.wkoecg.at/ecg.aspx?firmaid=3df78eaf-ec02-4b23-8938-eeab45553f38">Imprint</a></span>
                <span id="freepik">background by <a className="text-accent" href="https://www.freepik.com" target="_blank" rel="noreferrer">freepik.com</a></span>
            </footer>
        )
    }

    function FooterMobile() {
        return (
            <footer className={FooterStyle.mobile} data-testid="footer">
                <NavLink to={"/"}>
                    <HomeIcon/>
                </NavLink>
                <NavLink to={"/pendingGames"}>
                    <PlayCircleIcon/>
                </NavLink>
                <NavLink to={"/user"}>
                    <UserIcon/>
                </NavLink>
            </footer>
        );
    }

    return <>
        {isBelowSmallBreakpoint ? <FooterMobile/> : <FooterDesktop/>}
    </>;
}
