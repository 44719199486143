import React, { useEffect } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Dashboard from "./components/pages/Dashboard";
import { PlayerUpdatesProvider } from './components/PlayerUpdatesContext';
import {PendingGames} from "./components/pages/PendingGames";
import {User} from "./components/pages/User";
import Play from './components/pages/Play';
import { TicTacToe } from './components/games/TicTacToe';
import { ConnectFour } from './components/games/ConnectFour';

function App() {
  //Matomo Tag Manager
  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://analytics.games2.click/js/container_VoZ6scWe.js'; s.parentNode.insertBefore(g,s);
  }, [])

  return (
    <PlayerUpdatesProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/play/tictactoe/:playerSlug" element={
            <Play gameName='tictactoe'>
              <TicTacToe />
            </Play>
          } />
          <Route path="/play/connectfour/:playerSlug" element={
            <Play gameName="connectfour">
              <ConnectFour />
            </Play>
          } />
          <Route path="/pendingGames" element={<PendingGames />} />
          <Route path="/user" element={<User />} />
        </Routes>
      </BrowserRouter>
    </PlayerUpdatesProvider>
  );
}

export default App;
