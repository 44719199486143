import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import games2ClickClient from '../../Games2ClickClient';
import '../../assets/css/connect-four.css';
import { useNavigate } from 'react-router-dom';
import {ClipboardDocumentCheckIcon, ClipboardDocumentListIcon} from "@heroicons/react/16/solid";
import {Button, Typography} from "@material-tailwind/react";
import "./sharing.css";
import {SecondaryButton, ShareButton} from "../nav/Buttons";



export function OpponentLink(props){

  let navigate = useNavigate();
  const [newGame, setNewGame] = useState(props.newGame);
  const [shareOverlayVisible, setShareOverlayVisible] = useState(false);
  const [linkIsCopied, setLinkIsCopied] = useState(false);

  const [ opponent, setOpponent ] = useState(null)
  useEffect(() => {
    games2ClickClient.getPlayData(props.mySlug)
      .then(playData => {
        const opponent = playData.players.find(player => player.playerSlug !== props.mySlug);
        setOpponent(opponent);
    })
      .catch((err) => {
        err.then(error => {
          console.log('ERROR in getPlayData: ' + error.message);
          //alert(error.message);
          navigate("/")
        });
      });
  }, []);

  function showOpponentUrl() {
    console.log("should display shareOverlay? " +shareOverlayVisible +" || "+ newGame)

    const url = `${window.location.origin}/play/${props.gameName}/${opponent.playerSlug}`;

    const handleShareClick = async() => {
      try {
        if (navigator.share) {
          await navigator.share({
            title: 'Game Link',
            text: `Play ${props.gameName} with your opponent`,
            url: url
          });
        }else{
          console.error('Web Share API not supported');
        }
      }catch(error){
        console.error('Error sharing: ', error)
      }
    };

    return (
        <div className="flex flex-col items-center">
          <ShareButton className="h-16 w-16" blinkingCondition={newGame} onClick={() => setShareOverlayVisible(true)}></ShareButton>
          {(shareOverlayVisible || newGame) && <ShareOverlay gameName={props.gameName} shareHandler={handleShareClick} opponentUrl={url}/>}
        </div>
    );
  }

  function ShareOverlay(props) {
    return <div data-testid='shareOverlay' className="shareOverlay">
      <div className={`inner-overlay ${!linkIsCopied && 'animate'}`}>
        <div className="qr">
          <QRCode value={`${window.location.origin}/play/${props.gameName}/${opponent.playerSlug}`}
            style={{ height: "100%", width: "100%", }}>
          </QRCode>
        </div>
        <Typography variant="small" className="max-w-64 text-center">Let your opponent scan the qr code or share the link:</Typography>
        <div className="share-button-row flex flex-row items-start justify-evenly mt-3 w-full">
          { navigator.share && <ShareButton className="w-12 h-12" onClick={props.shareHandler} blinkingCondition={false}>Share link</ShareButton> }

                <Button className="bg-transparent flex flex-col items-center gap-2 m-0 p-0 w-12" onClick={()=> {
                  navigator.clipboard.writeText(props.opponentUrl);
                  setLinkIsCopied(true)
                }}>
                  {linkIsCopied ?
                      <ClipboardDocumentCheckIcon className="copy-to-clipboard done"></ClipboardDocumentCheckIcon> :
                      <ClipboardDocumentListIcon className="copy-to-clipboard"></ClipboardDocumentListIcon>
                  }
                  Copy to clipboard
                </Button>


        </div>
        <SecondaryButton className="mt-6 self-end bg-primary-950" onClick={() => {
          setShareOverlayVisible(false)
          setNewGame(false)
          setLinkIsCopied(false)
        }}>Done</SecondaryButton>

    </div>
    </div>
  }

  return <>
    {opponent && showOpponentUrl()}
  </>
}
export default OpponentLink;
