import { NavLink } from 'react-router-dom';
import games2ClickClient from '../../Games2ClickClient';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {Button} from "@material-tailwind/react";
import {TrashIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {HexIcon} from "../Hexagon";
import {ShareIcon} from "@heroicons/react/16/solid";

export function PrimaryButton(props) {
  return (
      <NavLink key={props.key} data-testid={props.id} to={props.to} className={props.className}>
        <Button variant="filled" size={`${props.size ? props.size : "sm"}`} color="white" className="bg-accent hover:shadow-accent/50 hover:text-white" onClick={props.onClick}>{props.children}</Button>
      </NavLink>
  )
}

export function SecondaryButton(props) {
  return (
      <NavLink key={props.key} data-testid={props.id} to={props.to} className={props.className}>
        <Button variant="outlined" size={`${props.size ? props.size : "sm"}`} color="white" className="hover:opacity-100 hover:bg-white/100 hover:text-black" onClick={props.onClick}>{props.children}</Button>
      </NavLink>
  )
}

export function ReplayButton(props) {
  const [playData, setPlayData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (playData) {
      const playerSlug = playData.players[0].playerSlug;
      navigate(`/play/${props.gameName}/${playerSlug}`);
      navigate(0);
    }
  }, [playData]);

  async function startReplay() {
    games2ClickClient.startNewGame(props.gameName)
      .then(data => setPlayData(data));
  }

  switch (props.as) {
    case "primary": return <PrimaryButton onClick={startReplay} id={'replay-link'} className={props.className}>NEW GAME</PrimaryButton>;
    case "secondary": return <SecondaryButton onClick={startReplay} id={'replay-link'} className={props.className}>NEW GAME</SecondaryButton>;
    default: return <PrimaryButton onClick={startReplay} id={'replay-link'} className={props.className}>NEW GAME</PrimaryButton>;
  }
}

export function RematchButton(props) {
  const [gameData, setGameData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (gameData) {
      //window.location.href = `/play/${props.gameName}/${gameData.players[0].playerSlug}`;
      navigate(`/play/${props.gameName}/${gameData.players[0].playerSlug}`);
      navigate(0);
    }
  }, [gameData]);

  async function startRematch() {
    games2ClickClient.rematch(props.playerSlug)
      .then(data => setGameData(data));
  }

  // notify opponent
  switch (props.as) {
    case "primary": return <PrimaryButton onClick={startRematch} id={'rematch-link'} className={props.className}>REMATCH</PrimaryButton>;
    case "secondary": return <SecondaryButton onClick={startRematch} id={'rematch-link'} className={props.className}>REMATCH</SecondaryButton>;
    default: return <PrimaryButton onClick={startRematch} id={'rematch-link'} className={props.className}>REMATCH</PrimaryButton>;
  }
}

export function DeleteButton(props) {
  return (
    <Button
        className={`flex items-center justify-center rounded-lg p-2 bg-transparent bg-opacity-100 shadow-none hover:bg-red-800 group/icon ${props.className}`}
        onClick={props.onClick}>
      {" "}
      {React.createElement(TrashIcon, {
        strokeWidth: 2,
        className: "h-5 text-white w-5 group-hover:text-black !group-hover/icon:text-white",
      })}
    </Button>
  )
}

export function CancelButton(props) {
  return (
      <Button
          className="flex items-center justify-center rounded-lg p-2 bg-transparent shadow-none hover:bg-gray-500"
          onClick={props.onClick}>
        {" "}
        {React.createElement(XMarkIcon, {
          strokeWidth: 2,
          className: "h-5 text-white w-5 group-hover:text-black",
        })}
      </Button>
  )
}

export function ShareButton(props) {
  return (
  <Button size="sm" className={`bg-transparent group relative !overflow-visible p-0 m-0 shrink-0 flex flex-col gap-2 ${props.className}`} onClick={props.onClick}>
    <HexIcon className={props.className}></HexIcon>
    {props.blinkingCondition && <HexIcon className="h-11 w-11 m-0 p-0 absolute top-[10px] left-[10px] animate-ping"></HexIcon> }
    <ShareIcon className="h-8 w-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary-950 fill-black group-hover:fill-white"></ShareIcon>
    {props.children}
  </Button>
  )
}
