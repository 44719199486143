import {Nav} from '../nav/Nav';
import {Footer} from '../Footer';
import {Typography} from "@material-tailwind/react";
import React from "react";

export function User () {
    return (
        <>
            <Nav />
            <main className="container flex flex-col justify-between p-5 md:p-0 items-center mx-auto h-[60vh]">
                <Typography variant="h1" className="text-center">Coming soon ...</Typography>
                <div id="imprint"><a className="text-accent" rel="noreferrer" target="_blank"
                                      href="https://www.wkoecg.at/ecg.aspx?firmaid=3df78eaf-ec02-4b23-8938-eeab45553f38">Imprint</a></div>
            </main>
            <Footer/>
        </>
    )
}