import React from "react";
import {
    Navbar,
    Collapse,
    Typography,
    IconButton,
    List,
    ListItem,
} from "@material-tailwind/react";
import {
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {Logo} from "../Logo";
import {UserHexIcon} from "../UserHexIcon";
import { Link } from 'react-router-dom';
import { PendingGamesMenu } from './PendingGamesMenu';
import {Breakpoint, useIsBelowBreakpoint} from "../Breakpoints";
import NavStyle from "./nav.module.css";

function NavList() {
    return (
        <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 *:text-white">
            <Link to='/' className="block antialiased font-sans text-xl leading-relaxed text-inherit font-medium" >
                <ListItem className="flex items-center gap-2 py-2 pr-4 hover:bg-white hover:bg-opacity-100 hover:text-black">
                    Home
                </ListItem>
            </Link>
            <PendingGamesMenu/>
            <Typography
                as="a"
                href="mailto:playground@games2.click"
                variant="lead"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4 hover:bg-white hover:bg-opacity-100">
                    Contact Us
                </ListItem>
            </Typography>
        </List>
    );
}

export function Nav() {
    const isBelowSmallBreakpoint = useIsBelowBreakpoint(Breakpoint.SMALL);
    const isBelowLargeBreakpoint = useIsBelowBreakpoint(Breakpoint.LARGE);
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        !isBelowLargeBreakpoint && setOpenNav(false)
    }, [isBelowLargeBreakpoint]);


    if(!isBelowSmallBreakpoint) {
        return (
            <Navbar
                className={NavStyle.desktop}>
                <div className="flex items-center justify-between *:text-white">
                    <Link to='/' className='mr-4 cursor-pointer py-1.5 lg:ml-2'>
                        <Logo/>
                    </Link>
                    <div className="hidden lg:block">
                        <NavList/>
                    </div>
                    <UserHexIcon
                        className="h-10 w-10 text-black hover:scale-125 transition-transform hover:text-white hidden lg:flex"/>
                    <IconButton
                        variant="text"
                        className="lg:hidden"
                        onClick={() => setOpenNav(!openNav)}
                    >
                        {openNav ? (
                            <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
                        ) : (
                            <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
                        )}
                    </IconButton>
                </div>
                <Collapse open={openNav}>
                    <NavList/>
                    <UserHexIcon className="h-8 w-8 lg:hidden"/>
                </Collapse>
            </Navbar>
        );
    } else {
        return (
            <Navbar className={NavStyle.mobile}>
                <Logo/>
            </Navbar>
        )
    }
}
