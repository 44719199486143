import React, { useEffect, useState } from 'react';
import games2ClickClient from '../../Games2ClickClient';
import Card from './Card';
import {Carousel as Carousel3D} from "react-responsive-3d-carousel";
import {Carousel} from "@material-tailwind/react";
import {PrimaryButton} from "../nav/Buttons";
import {Breakpoint, useIsBelowBreakpoint} from "../Breakpoints";
import { useNavigate } from 'react-router-dom';

function CardGrid() {
    const [gameCards, setGameCards] = useState(null);
    const isBelowMediumBreakpoint = useIsBelowBreakpoint(Breakpoint.MEDIUM);
    const navigate = useNavigate();

    useEffect(() => {
        games2ClickClient.getGameCards()
            .then(cards => {
                setGameCards(cards);
            });
    }, []);

    const startGame = (gameName) => {
        games2ClickClient.startNewGame(gameName)
            .then(playData => {
                const playerSlug = playData.players[0].playerSlug;
                navigate(`/play/${gameName}/${playerSlug}`);
            });
    };

    function CarouselDesktop() {
        return (
            <Carousel3D
                autoPlay={false}
                interval={7000}
                spread={"wide"}
                infiniteLoop={true}
                depth={2}
                width="300px"
                height="224px"
                transitionTime={250}
                arrowsHeight='32px'
                arrowsWidth="32px"
                arrowsHoveredColor="#FBCE27"
                showIndicators={false}
                isStatusShadow={false}
            >
                {
                    gameCards && gameCards.length > 0 ? gameCards.map(gameCard => {
                        return <Card key={gameCard.name + '_card'} gameCard={gameCard} className="game-card">
                            <PrimaryButton key={gameCard.name + '_playButton'} id="card-link"
                                           onClick={() => startGame(gameCard.name)}
                                           className="self-end mt-auto">
                                Play
                            </PrimaryButton>
                        </Card>;
                    }) : <Card key='error_card' gameCard={{title: "Error", description: "Games could not be loaded."}}/>
                }
                <Card key="comingSoon_card" gameCard={{title: "", description: "more games coming soon ..."}}/>
            </Carousel3D>
        );
    }

    function CarouselMobile() {
        return (
            <Carousel navigation={()=>{}} className="mb-16 pb-14" transition={{type: "tween", duration: 0.2}} loop={true}>
                {
                    gameCards && gameCards.length > 0 ? gameCards.map(gameCard => {
                        return <Card key={gameCard.name + '_card'} gameCard={gameCard}
                                     className="game-card bg-secondary-950/100 w-3/5 mx-auto min-h-[50vh]">
                            <PrimaryButton size="lg" key={gameCard.name + '_playButton'} id="card-link"
                                           onClick={() => startGame(gameCard.name)}
                                           className="self-center mt-auto mb-2">
                                Play
                            </PrimaryButton>
                        </Card>;
                    }) : <Card key='error_card' gameCard={{title: "Error", description: "Games could not be loaded."}}/>
                }
            </Carousel>
        );
    }

    if (gameCards != null) return (
        <main className="w-full md:my-auto mt-3 md:mt-0">
            <h1 className="m-5 ml-5">Total games: <span title="total games">{gameCards.length}</span></h1>
            {!isBelowMediumBreakpoint ? <CarouselDesktop/> : <CarouselMobile/>}
        </main>
    );
}

export default CardGrid;
