import React, { useEffect, useState } from 'react';
import games2ClickClient from '../../Games2ClickClient';
import OpponentLink from './OpponentLink';
import { RematchButton, ReplayButton } from '../nav/Buttons';
import player from '../../Player';
import { usePlayerUpdatesContext } from '../PlayerUpdatesContext';
import { ThreeDots } from 'react-loader-spinner';
import { Typography } from '@material-tailwind/react';
import { PlayContext } from '../PlayContext';
import { Nav } from '../nav/Nav';
import Card from '../dashboard/Card';
import { Footer } from '../Footer';
import { useParams } from 'react-router-dom';
import {Breakpoint, useIsBelowBreakpoint} from "../Breakpoints";

export function Play(props) {
  const { playerSlug } = useParams();
  const { playerUpdate } = usePlayerUpdatesContext();

  const [gameCard, setGameCard] = useState(null);

  const [newGame, setNewGame] = useState(false);
  const [turns, setTurns] = useState(null);
  const [playable, setPlayable] = useState(false);
  const [gameOverMessage, setGameOverMessage] = useState(null);
  const [playMessage, setPlayMessage] = useState(null);
  const [showRematch, setShowRematch] = useState(false);
  const isBelowMediumBreakpoint = useIsBelowBreakpoint(Breakpoint.MEDIUM)
  //const HowToWin = <a href='https://www.wikihow.com/Win-at-Tic-Tac-Toe'>Cheat</a>;

  useEffect(() => {
    games2ClickClient.getGameCard(props.gameName)
      .then(gameCard => setGameCard(gameCard));

    player.getPlayerId().then(playerId => {
      games2ClickClient.registerPlayerId(playerSlug, playerId)
        .catch((err) => {
          err.then(error => {
            console.log('ERROR in registerPlayer: ' + error.message);
            alert(error.message);
          });
        });
    });

    games2ClickClient.getPlayState(playerSlug)
      .then(playState => updatePlayState(playState));
  }, []);

  useEffect(() => {
    if ('updategame' === playerUpdate.type && playerUpdate.payload.playerSlug === playerSlug) {
      console.log('got game update, refreshing board');
      updatePlayState(playerUpdate.payload.playState);
    }
  }, [playerUpdate, playerSlug]);

  function updatePlayState(playState) {
    console.log(`updating Board of ${playerSlug}`);
    setNewGame(playState.turns.length === 0
      && playState.mySymbol === playState.nextPlayer);

    setTurns(playState.turns);

    if (playState.result) {
      setPlayable(false);
      if (playState.result.winner) {
        if (playState.result.winner === playState.mySymbol) {
          setGameOverMessage('Congratulations, you won!');
        } else {
          setGameOverMessage(`You lost - ${playState.result.winner} won the game.`);
          setShowRematch(true);
        }
      } else {
        setGameOverMessage('Draw');
        setShowRematch(true);
      }
    } else {
      if (playState.mySymbol === playState.nextPlayer) {
        setPlayable(true);
        setPlayMessage(YourTurn(playState));
      } else {
        setPlayable(false);
        setPlayMessage(Waiting());
      }
    }
  }

  function YourTurn(playState) {
    return <div className='flex flex-col items-center'>Your turn!<Typography
      className='text-accent text-4xl md:text-8xl mt-1 md:mt-4'>{playState.mySymbol}</Typography></div>;
  }

  function Waiting() {
    return <div>
      waiting for opponent
      <ThreeDots visible={true}
                 height='40'
                 width='40'
                 radius='48'
                 color='#FBCE27'
                 ariaLabel='watch-loading'
                 wrapperStyle={{ justifyContent: 'center' }}
                 wrapperClass=''
      />
    </div>;
  }

  function makeMove(box) {
    if (playable) {
      player.getPlayerId().then(playerId =>
        games2ClickClient.makeMove(playerId, playerSlug, box))
        .then(gameState => updatePlayState(gameState))
        .catch((err) => {
          err.then(error => {
            console.log('ERROR in makeMove: ' + error.message);
            alert(error.message);
          });
        });
    } else {
      console.log('board not playable');
      // alert('invalid move');
    }
  }

  if (turns != null) return (
    <>
      <PlayContext.Provider value={{ playable, makeMove, turns }}>
        <Nav />
        <main className='container flex flex-col items-center md:m-auto mt-3 md:mt-0'>
          <div className='container flex flex-col md:flex-row justify-center items-center mx-auto gap-x-16'>
            <div className='flex-1 order-3 md:order-first'>
              <OpponentLink gameName={props.gameName} mySlug={playerSlug} newGame={newGame} />
            </div>
            {props.children}
            <div className='state order-first md:order-3 flex-1 flex flex-col justify-center items-center'>
              {playMessage ? playMessage : ''}
            </div>
          </div>
          {gameOverMessage && GameOver()}
          { !isBelowMediumBreakpoint && gameCard && <Card gameCard={gameCard} className='w-60 sm:w-80 mt-8' /> }
        </main>
        <Footer />
      </PlayContext.Provider>
    </>
  );

  function GameOver() {
    return <div
      className='game-over fixed w-[100vw] h-[100vh] top-0 bg-black/80 z-10 flex flex-col justify-center items-center gap-4 md:gap-10'>
      <Typography className='text-lg sm:text-2xl md:text-4xl'>
        <div className='game-over-msg'>{gameOverMessage}</div>
      </Typography>
      {showRematch ? RematchButtons() : ReplayButtons()}
    </div>;
  }

  function RematchButtons() {
    return <div className='container flex justify-center gap-x-10'>
      <ReplayButton gameName={props.gameName} as='secondary' className='replay' />
      <RematchButton gameName={props.gameName} playerSlug={playerSlug} className='rematch' />
    </div>;
  }

  function ReplayButtons() {
    return <ReplayButton gameName={props.gameName} />;
  }

}

export default Play;
