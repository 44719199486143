import React, { useState } from 'react';
import games2ClickClient from '../../Games2ClickClient';
import { Badge, MenuItem, Typography } from '@material-tailwind/react';
import { CancelButton, DeleteButton } from './Buttons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClockIcon, PlayCircleIcon } from '@heroicons/react/16/solid';

export function PendingGameItem(props) {
  const pendingGame = props.pendingGame;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [gameToDelete, setGameToDelete] = useState(undefined);
  function deletePendingGame(pendingGame){
    setGameToDelete(pendingGame);
  }

  function confirmDeletePendingGame() {
    games2ClickClient.removePendingGame(gameToDelete.playSlug.playerSlug);
    if(pathname === `/play/${gameToDelete.game.name}/${gameToDelete.playSlug.playerSlug}`) {
      navigate("/");
    }
    setGameToDelete(undefined);
  }

  return <MenuItem
    className='flex relative my-2 lg:my-0 h-16 min-w-64 items-center gap-3 rounded-lg bg-secondary-950 group hover:bg-accent/100'>
    {pendingGame.myTurn && <div
      className='z-49 absolute animate-ping bg-accent lg:right-0 -mr-[12px] p-0 top-0 left-0 max-w-6 w-6 h-6 rounded-full'></div>}
    {undefined !== gameToDelete && gameToDelete.playSlug.playerSlug === pendingGame.playSlug.playerSlug ?
      <div className='flex justify-end items-start w-full'>
        <Typography variant='lead' color='white'
                    className='mx-auto group-hover:text-red-900 pe-5'>Delete?</Typography>
        <DeleteButton onClick={() => confirmDeletePendingGame()} />
        <CancelButton onClick={() => setGameToDelete(undefined)} />
      </div> :
      <div className='flex justify-start gap-4 items-center w-full'>
        <Link to={`/play/${pendingGame.game.name}/${pendingGame.playSlug.playerSlug}`}
              key={pendingGame.playSlug.playerSlug} className={`flex gap-2`}>
          <Badge
            content={pendingGame.myTurn ?
              '!' :
              React.createElement(ClockIcon, {
                  strokeWidth: 2,
                  className: `h-5 w-5 text-gray-800`,
                },
              )}
            placement={'top-start'}
            withBorder
            className={`border-2 border-secondary-950 p-0 text-black ${pendingGame.myTurn ? 'bg-accent' : 'bg-gray-500'}`}
          >
            <div className='flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 '>
              {' '}
              {React.createElement(PlayCircleIcon, {
                strokeWidth: 2,
                className: 'h-6 text-gray-900 w-6',
              })}
            </div>
          </Badge>
          <div>
            <Typography
              variant='h6'
              className='flex items-center text-lg font-bold'
            >
              {pendingGame.game.title}
            </Typography>
            <Typography
              variant='paragraph'
              className='text-xs !font-medium text-blue-gray-500'
            >
              {new Date(pendingGame.created_at).toLocaleString()}
            </Typography>
          </div>
        </Link>
        <DeleteButton className='ms-auto' onClick={() => deletePendingGame(pendingGame)} />
      </div>
    }
  </MenuItem>;
}
