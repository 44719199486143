import { useContext, useEffect, useState } from 'react';
import { PlayContext } from '../PlayContext';
import '../../assets/css/3ts.css';

export function TicTacToe() {
  const {playable,makeMove, turns} = useContext(PlayContext);

  const [board, setBoard] = useState([['', '', ''], ['', '', ''], ['', '', '']]);

  useEffect(() =>{
      let newBoard = board.slice();
      for (let turn of turns) {
        let row = Math.floor(turn.box / 3);
        let col = turn.box % 3;
        newBoard[row][col] = turn.symbol;
      }
      setBoard(newBoard);
  }, [turns]);

  function handleMove(box: number) {
    let row = Math.floor(box / 3);
    let col = box % 3;
    if (playable && validateMove(row, col)) {
      console.log('move at: ' + row + ': ' + col);
      makeMove({ box: box });
    }
  }

  function validateMove(row: number, col: number) {
    return (board[row][col] === '');
  }

  return <table className={`TTT-board flex-1" ${!playable ? 'disabled' : ''}`} data-testid='board'>
    <tbody>
    <tr>
      <td onClick={() => handleMove(0)}>{board[0][0]}</td>
      <td onClick={() => handleMove(1)}>{board[0][1]}</td>
      <td onClick={() => handleMove(2)}>{board[0][2]}</td>
    </tr>
    <tr>
      <td onClick={() => handleMove(3)}>{board[1][0]}</td>
      <td onClick={() => handleMove(4)}>{board[1][1]}</td>
      <td onClick={() => handleMove(5)}>{board[1][2]}</td>
    </tr>
    <tr>
      <td onClick={() => handleMove(6)}>{board[2][0]}</td>
      <td onClick={() => handleMove(7)}>{board[2][1]}</td>
      <td onClick={() => handleMove(8)}>{board[2][2]}</td>
    </tr>
    </tbody>
  </table>;
}
