function Card(props) {
  const classNames = ['card'];
  if(props.className)
    classNames.push(props.className);

  return <div className={`rounded-lg bg-secondary-950/90 border-secondary-950 border-2 backdrop-blur-sm flex flex-col items-center justify-center max-h-56 p-5 ${classNames.join(' ')}`} data-testid='gamecard'>
      <h2 className="text-lg mb-4">{props.gameCard.title}</h2>
      <p>{props.gameCard.description}</p>
      {props.children}
    </div>;
}

export default Card;
