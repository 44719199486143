import { createContext } from 'react';

interface PlayContextData {
  playable: boolean,
  makeMove: any,
  turns: any
}

export const PlayContext = createContext<PlayContextData>({
  playable: false,
  makeMove: null,
  turns: [],
});
