import { useContext, useEffect, useState } from 'react';

import { createContext } from 'react';
import games2ClickClient from '../Games2ClickClient';
import player from '../Player';

export const PlayerUpdatesContext = createContext({});

export const PlayerUpdatesProvider = ({ children }) => {
  const [playerUpdate, setPlayerUpdate] = useState({});

  useEffect(() => {
    player.getPlayerId()
      .then(playerId =>
        games2ClickClient.addPlayerUpdatesListener(playerId, (eventData) => {
          console.debug('PlayerUpdates event:' + eventData);
          setPlayerUpdate(eventData);
        }));
  }, []);

  return (
    <PlayerUpdatesContext.Provider value={{ playerUpdate }}>
      {children}
    </PlayerUpdatesContext.Provider>
  );
};

export const usePlayerUpdatesContext = () => {
  return useContext(PlayerUpdatesContext);
};
