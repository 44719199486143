import { useContext, useEffect, useState } from 'react';
import $ from 'jquery';
import { PlayContext } from '../PlayContext';
import '../../assets/css/connect-four.css';

export function ConnectFour() {
  const {playable,makeMove, turns} = useContext(PlayContext);

  const [board, setBoard] = useState(Array.from({ length: 6 }, () => Array.from({ length: 7 }, () => '')));

  useEffect(() => {
    const newBoard = Array.from(     { length: 6 }, () => Array.from({ length: 7 }, () => ''));
    for (let turn of turns) {
      newBoard[turn.row][turn.column] = turn.symbol;
    }
    setBoard(newBoard);
  }, turns);

  useEffect(() => {
    //window.$ = $;
    $(document).ready(function() {
      const columns = [];
      for (let col in board[0]) {
        columns.push(`.td-${col}`);
      }
      columns.forEach((col) => {
        $(col).hover(function() {
          $(col).addClass('highlight-column');
        }).mouseleave(function() {
          $(col).removeClass('highlight-column');
        });
      });
    });
  }, []);

  async function handleMove(column: number) {
    if (playable && validateMove(column)) {
      const dropSound = require('../../assets/sound/game_piece_drop.mp3');
      const audio = new Audio(dropSound);
  
      try {
        await audio.play();
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
      makeMove({
        column: column,
      });
    }
  }

  function validateMove(column: number) {
    return board[0][column] === '';
  }

  return <div className='connect-four-container'>
    <table className={`connect-four-board ${!playable ? 'disabled' : ''}`} data-testid='board'>
      <tbody>
      {board.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((cell, columnIndex) => (
            <td key={columnIndex} onClick={() => makeMove(columnIndex)}>
              {cell !== '' && <div className={`circle ${cell}`}></div>}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
    <table className={`connect-four-board-top-layer ${!playable ? 'disabled' : ''}`} data-testid='board-top-layer'>
      <tbody>
      {board.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((cell, columnIndex) => (
            <td key={columnIndex} className={`td-${columnIndex}`} onClick={() => handleMove(columnIndex)}>

            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  </div>;
}
