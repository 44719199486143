import {useEffect, useState} from "react";

/**
 * see https://www.material-tailwind.com/docs/react/screens
 */
export enum Breakpoint {
    SMALL = 540,
    MEDIUM = 720,
    LARGE = 960
}

/**
 * @Returns: _true_ if viewport width (_window.innerWidth_) is below **breakpoint**, _false_ otherwise
 * @param breakpoint Static field of Breakpoint, e.g. Breakpoint.MEDIUM
 * @type {(breakpoint :Breakpoint) => Boolean}
 */
export function useIsBelowBreakpoint(breakpoint :Breakpoint): boolean {
    const [isBelow, setBelow] = useState(window.innerWidth < breakpoint);
    useEffect(() => {
        window.addEventListener(
            "resize",
            () => setBelow(window.innerWidth < breakpoint),
        );
    });
    return isBelow;
}