import games2ClickClient from './Games2ClickClient';

class Player {

  async getPlayerId() {
    let playerId = localStorage.getItem('playerId');
    if(playerId) {
      return playerId;
    }else {
      return games2ClickClient.createPlayer()
        .then(player => {
          localStorage.setItem('playerId', player.playerId);
          return player.playerId;
        })
    }
  }
}

const player = new Player();
export default player;
