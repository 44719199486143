class Games2ClickClientImpl {
  API_BASEURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

  //games
  getGameCards() {
    return fetch(`${this.API_BASEURL}/api/games/`, {
      method: 'GET',
      headers: [['Accept-Language', navigator.language]],
    })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in getGameCards: ' + error));
  }

  //games
  getGameCard(gameName: string) {
    console.log(`Fetching data from ${this.API_BASEURL}/api/games/${gameName}`);
    return fetch(`${this.API_BASEURL}/api/games/${gameName}`, {
      method: 'GET',
      headers: [['Accept-Language', navigator.language]],
    })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in getGame: ' + error));
  }

  //player
  createPlayer() {
    return fetch(`${this.API_BASEURL}/api/players`, { method: 'POST' })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in createPlayer: ' + error));
  }

  //player
  addPlayerUpdatesListener(playerId: string, callback: any) {
    const evtSource = new EventSource(`${this.API_BASEURL}/api/players/${playerId}/updates`);
    evtSource.onmessage = (event) => {
      console.debug(`${playerId}/updates ` + { event });
      let eventData = JSON.parse(event.data);
      callback(eventData);
    };
  }

  getPendingGames(playerId: string) {
    return fetch(`${this.API_BASEURL}/api/players/${playerId}/pending`, {
      method: 'GET',
      headers: [['Accept-Language', navigator.language]],
    })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in createPlayer: ' + error));
  }

  removePendingGame(playerslug: string) {
    fetch(`${this.API_BASEURL}/api/plays/${playerslug}`, {method: 'DELETE'})
        .then(response => {
          console.log(response);
        })
        .catch((error) => console.log('ERROR in removePendingGame: ' + error));
  }


  //pays
  startNewGame(gameName: string) {
    return fetch(`${this.API_BASEURL}/api/plays/${gameName}`, { method: 'POST' })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in startNewGame: ' + error));
  }

  getPlayState(playerSlug: string) {
    return fetch(`${this.API_BASEURL}/api/plays/${playerSlug}`, { method: 'GET' })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in getPlayState: ' + error));
  }

  registerPlayerId(playerSlug: string, playerId: string) {
    return fetch(`${this.API_BASEURL}/api/plays/${playerSlug}/players/${playerId}`, { method: 'POST' })
      .then(response => response.json())
  }

  getPlayData(playerSlug: string) {
    return fetch(`${this.API_BASEURL}/api/plays/${playerSlug}/players`, { method: 'GET' })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response.json());
      });
  }

  makeMove(playerId: string, playerSlug: string, turn: any) {
    return fetch(`${this.API_BASEURL}/api/plays/${playerSlug}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'playerId': playerId,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ move: turn }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response.json());
      });
  }

  rematch(playerSlug: string) {
    return fetch(`${this.API_BASEURL}/api/plays/${playerSlug}/rematch`, { method: 'POST' })
      .then(response => response.json())
      .catch((error) => console.log('ERROR in rematch: ' + error));
  }

}

class Games2ClickClient {

  client = new Games2ClickClientImpl();

  getGameCards() {
    return this.client.getGameCards();
  }

  getGameCard(gameName: string) {
    return this.client.getGameCard(gameName);
  }

  createPlayer() {
    return this.client.createPlayer();
  }

  addPlayerUpdatesListener(playerId: string, callback:any) {
    return this.client.addPlayerUpdatesListener(playerId, callback);
  }

  getPendingGames(playerId: string) {
    return this.client.getPendingGames(playerId);
  }

  removePendingGame(playerslug: string) {
    return this.client.removePendingGame(playerslug);
  }

  startNewGame(gameName: string) {
    return this.client.startNewGame(gameName);
  }

  getPlayState(playerSlug: string) {
    return this.client.getPlayState(playerSlug);
  }

  registerPlayerId(playerSlug: string, playerId: string) {
    return this.client.registerPlayerId(playerSlug, playerId);
  }

  getPlayData(playerSlug: string) {
    return this.client.getPlayData(playerSlug);
  }

  makeMove(playerId: string, playerSlug: string, turn: any) {
    return this.client.makeMove(playerId, playerSlug, turn);
  }

  rematch(playerSlug: string) {
    return this.client.rematch(playerSlug);
  }
}

const games2ClickClient = new Games2ClickClient();
export default games2ClickClient;
