import React, { useEffect, useState } from 'react';
import { usePlayerUpdatesContext } from '../PlayerUpdatesContext';
import player from '../../Player';
import games2ClickClient from '../../Games2ClickClient';
import { PendingGameItem } from '../nav/PendingGameItem';
import {Footer} from "../Footer";
import NavStyle from "../nav/nav.module.css";
import {Logo} from "../Logo";
import {Navbar, Typography} from "@material-tailwind/react";

export function PendingGames() {
  const {playerUpdate} = usePlayerUpdatesContext();
  const [pendingGames, setPendingGames] = useState(null);

  useEffect(() => fetchPendingGames(), []);
  useEffect(() => {
    if(['newgame','updategame','removegame'].find(item => item === playerUpdate.type)) {
      console.log("got new game, refreshing pending games");
      fetchPendingGames();
    }
  }, [playerUpdate]);


  function fetchPendingGames() {
    player.getPlayerId()
      .then(playerId => games2ClickClient.getPendingGames(playerId))
      .then(games => {
        setPendingGames(games);
      });
  }

  const renderItems = pendingGames && pendingGames.length > 0 &&
    pendingGames.map((pendingGame) => <PendingGameItem key={pendingGame.game.id} pendingGame={pendingGame} />)

  if (pendingGames != null) return (
      <>
          <Navbar className={NavStyle.mobile}>
              <Logo/>
          </Navbar>
        <div className={`p-5 ${pendingGames.length === 0 && 'my-auto'}`}>
          {pendingGames.length > 0 ?
              <>
              <Typography variant="lead">My games</Typography>
                  {renderItems}
              </> :
              <Typography variant="paragraph" className="my-auto text-center">
                  No pending games - click on the home button and start a new game!
              </Typography>
             }
        </div>
        <Footer />
      </>
  );
}
