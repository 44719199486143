import CardGrid from '../dashboard/CardGrid';
import { Nav } from '../nav/Nav';
import { Footer } from '../Footer';

export function Dashboard() {

  return (
    <>
      <Nav />
      <CardGrid />
      <Footer />
    </>
  );
}

export default Dashboard;
